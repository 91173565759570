/*
* 업무구분: 신탁>고객/상품>고객정보조회>신탁운용자산현황
* 화 면 명: MSPTS102M
* 화면설명: 신탁운용자산현황 [8010]
* 작 성 일: 2023.04.13
* 작 성 자: 지재호
* 파 일 명: TFSL80100.xfdl
*/
<template>
  <ur-page-container class="fts" :show-title="false" title="신탁운용자산현황" :topButton="true">

    <!-- header start -->
    <ts-header
      ref="tsHeader"
      :propObj="pHeaderObj"
    ></ts-header>
    <!-- header end -->

    <!-- .fts-main -->
    <div class="fts-main">
      
      <!-- 계좌번호 콤보화면 -->
      <msp-ts-95090D
        ref="search"
        @ts-alert-popup="fn_AlertPopup"
        @ts-popup-callback="fn_Popup_CallBack"      
        @ts-fn-init="fn_init"
        @ts-fn-search-list="fn_searchList"
        :dSearchObj="searchObj"
      ></msp-ts-95090D>

      <ur-box-container direction="column" alignV="start">                 
        <div class="wrap-table-title row justify-between">
          <h2 class="table-title"> 신탁계좌 평가금액 / 투자수익률 현황 </h2>
          <em class="colorR text-xs"> ※ 본 내역은 기준일자 종가 기준 평가내역이며, 실제 해지금액과 차이가 있을 수 있습니다. </em>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2228">
            <tbody>
              <tr>
                <th>
                  <span> 신탁상품 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-text-field class="w130" v-model="ds_detail.gdC" disabled />
                    <mo-text-field class="input-long" v-model="ds_detail.gdCNm" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span>발급일자 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker v-model="ds_detail.bizDate" :bottom="false" class="input-long" disabled />
                  </div>
                </td>
                <th>
                  <span> 계약일자 </span>
                </th>
                <td>
                   <div class="wrap-input row">
                    <mo-date-picker v-model="ds_detail.contDate" :bottom="false" class="input-long" disabled/>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 수탁원본 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-text-field v-model="ds_detail.trstAmt" class="input-long input-text-right" disabled />
                  </div>
                </td>
                <th>
                  <span> 평가금액(자산평가금액-보수) </span>
                </th>
                <td>
                 <div class="wrap-input">
                    <mo-text-field v-model="ds_detail.estmAmt" class="input-long input-text-right" disabled />
                  </div>
                </td>
              </tr>
             <tr>
                <th>
                  <span> 신탁수익(운용수익-보수) </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-text-field v-model="ds_detail.estmPlSum" class="input-long input-text-right" disabled />
                  </div>
                </td>
                <th>
                  <span> (세전)신탁수익율 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <span> 연 </span>
                    <mo-text-field v-model="ds_detail.estmPrrt" class="input-long input-text-right" disabled />
                    <span> % </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
		  </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row justify-between">
          <h2 class="table-title"> 신탁 운용자산 현황 </h2>
          <em class="colorR text-xs"> ※ 신탁 운용자산현황의 자산별 평가금액은 기 납부된 분기원천세 만큼 차감된 금액 입니다. </em>
        </div>
        <div class="wrap-table">
          <table class="table row-type">
            <thead>
              <tr>
                <th> 자산유형 </th>
                <th> 종목명 </th>
                <th> 수량 </th>
                <th> 잔고단가 </th>
                <th> 장부금액 </th>
                <th> 평가금액 </th>
                <th> 구성비율 </th>
                <th> 평가단가 </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in ds_list" :key="i">
                <td> {{row.snbGdNm}} </td>
                <td class="aL"> {{row.itemNm}} </td>
                <td class="aR"> {{$bizUtil.numberWithCommas(row.accbQty)}} </td>
                <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.accbUpr).toFixed(2))}} </td>
                <td class="aR"> {{$bizUtil.numberWithCommas(row.accbAmt)}} </td>
                <td class="aR"> {{$bizUtil.numberWithCommas(row.estmAmt)}} </td>
                <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.consRto).toFixed(2))}} </td>
                <td class="aR"> {{$bizUtil.numberWithCommas(Number(row.estmUpr).toFixed(2))}} </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td> 합계 </td>
                <td> {{ds_list_total.trDate}} </td>
                <td class="aR"> {{/*$bizUtil.numberWithCommas(ds_list_total.accbQty)*/}} </td>
                <td class="aR"> {{/*$bizUtil.numberWithCommas(ds_list_total.accbUpr)*/}} </td>
                <td class="aR"> {{$bizUtil.numberWithCommas(ds_list_total.accbAmt)}} </td>
                <td class="aR"> {{$bizUtil.numberWithCommas(ds_list_total.estmAmt)}} </td>
                <td class="aR"> {{/*$bizUtil.numberWithCommas(Number(ds_list_total.consRto).toFixed(2))*/}} </td>
                <td class="aR"> {{/*$bizUtil.numberWithCommas(Number(ds_list_total.estmUpr).toFixed(2))*/}} </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </ur-box-container>
    </div>
    <!-- //.fts-main -->
    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * DataSet 영역	                                                                    *
   ***********************************************************************************/
  const defaultDsList = {  
    trDate: '',
    fundC: '',
    snbGdC: '',
    snbGdNm: '',
    stndItemC: '',
    itemNm: '',
    accbQty: '',
    accbUpr: '',
    estmUpr: '',
    estmAmt: '',
    consRto: '',
    sprEstmTagtYn: '',
    accbAmt: '',
  }

  const defaultDsSearch = {  
    tacno: '',
    accSeq: '',
    baseDate: '',
    bnkbSeq: '',
    acctNo: '',
    cnm: '',
    trstAmt: '',
    bizDate: '',
  }

  const defaultDsDetail = {  
    baseDate: '',
    contDate: '',
    gdC: '',
    gdCNm: '',
    trstAmt: '',
    estmPlSum: '',
    estmPrrt: '',
    accbAmt: '',
    estmAmt: '',
    bizDate: '',
  }
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
  import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
  import TSComDate from '~/src/ui/ts/comm/TSComDate'
  import TSHeader from '~/src/ui/ts/comm/TSHeader'          // header 영역 (공통)
  import MSPTS95090D from '~/src/ui/ts/MSPTS95090D' // 계좌조회
  import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)
  import TSInfoUtil    from '~/src/ui/ts/comm/TSInfoUtil'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPTS102M",
    screenId: "MSPTS102M",
    components: {
      'ts-header': TSHeader,
      'msp-ts-95090D': MSPTS95090D,
      'ts-alert-popup': TSAlertPopup,
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      
    },
    mounted() {
      this.fn_afterFormOnload()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        
        // 공통 객체
        pHeaderObj: {          
          title: '신탁운용자산현황',
          type: 'custInfoReg',
          step: 1,
        },
        searchObj: {cal_baseDate: '', m_id:'MSPTS102M'}, // 검색 컴포넌트 객체 MSPTS95090D
        eaiCommObj: TSCommUtil.gfn_eaiCommObj(), // Contents Model  
        pAlertPopupObj: {}, // 공통 객체

        lv_basInfo: this.getStore('tsStore').getters.getBasInfo.data,
        isMobile: window.vue.getStore('deviceState').getters.getIsMobile,

        // global
        sSvcID: '',
        gBizDate: '', // 영업일자
        prevBusyDate: '', // 영업일자 전
        ds_search: {},
        ds_list: [],
        ds_list_total: Object.assign({}, defaultDsList), 
        ds_detail: Object.assign({}, defaultDsDetail),

      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      itemSlt1() {
        let rtn = [];
        rtn.push({value: '1', text: '리치플랜외화채권(U)'});
        rtn.push({value: '2', text: '리치플랜외화채권2'});
        return rtn;
      },

      // modal
      modal1() {return this.$refs.modal1},
      modal2() {return this.$refs.modal2},
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_test(pNumber, decimal){
        let returnNum = ''
        if ( Number.isInteger(Number(pNumber)) ) {
          returnNum
        }
      },

      /***************************************************************************************
        실질적인 화면 초기화 
      *****************************************************************************************/
      fn_afterFormOnload ()
      {
        this.fn_init();
      },

      /***************************************************************************************
        초기화 
      *****************************************************************************************/
      fn_init ()
      {
        // 유저 정보 세팅
        // let vm = this
        // vm.$bizUtil.tsUtils.selBasInfo(vm, false).then(function (response) {
        //   vm.lv_basInfo = response.body
        //   //날자설정 및 Combo 설정
        //   vm.gBizDate = vm.lv_basInfo.busyDate // this.gfn_getBusyDate()
        //   vm.prevBusyDate = vm.lv_basInfo.prevBusyDate // this.gfn_getPrevBusyDate()
        //   vm.searchObj.cal_baseDate = TSCommUtil.gfn_dateReplace(1, vm.prevBusyDate) // 계좌조회 컴포넌트에 전달할 값
        // }).catch(function (error) {
        //   window.vue.error(error)
        // })
        
        this.gBizDate = this.lv_basInfo.busyDate 
        this.prevBusyDate = this.lv_basInfo.prevBusyDate
        this.searchObj.cal_baseDate = TSCommUtil.gfn_dateReplace(1, this.lv_basInfo.prevBusyDate) // 계좌조회 컴포넌트에 전달할 값

        //상세 데이터셋 row 초기화후
        Object.assign(this.ds_search, defaultDsSearch)
        Object.assign(this.ds_detail, defaultDsDetail)
        this.ds_list = []
        Object.assign(this.ds_list_total, defaultDsList)

        // 계좌조회 컴포넌트 초기화
        this.$refs.search._fn_dataInit();

        this.fn_rmAllErrCss() // css error 초기화
      },

      
      /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type) : 0: 계좌조회 
       ******************************************************************************/
      fn_Popup_CallBack(type, pData) {
        console.log(pData)
        let t_popupObj = {}

        switch (type) {
          case 0:
            console.log('계좌조회 팝업')
            break
        }
      },

      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
      ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        switch (type) {
          case 0:
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
              TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
            }
            this.$refs.alertPopup.fn_Open()     
          break
        }
      },

      // /**********************************************************************************
      //  * 조회 : WFtitleCRUDbtn 의 callback
      //  **********************************************************************************/
      fn_searchList ()
      {

        //조회 데이터셋 row 초기화후 row추가 : row 없으면 입력 불가능.	
        let acctNo = "";

        //조회용 dataset에 조회할 항목을 넣어준다.
        Object.assign(this.ds_search, defaultDsSearch)
        this.ds_search.tacno = this.$refs.search._getTacnoValue()
        this.ds_search.bnkbSeq = this.$refs.search._getBnkbSeqValue()
        this.ds_search.accSeq = this.$refs.search._getAccSeqValue()
        this.ds_search.baseDate = this.$refs.search.$refs.cal_baseDate.value.replaceAll("-","")
        
        acctNo = this.$refs.search._getTacnoValue() + this.$refs.search._getBnkbSeqValue() + this.$refs.search._getAccSeqValue()
        
        /////
        // this.fn_addValidErr(this.$refs.search.$refs.Edt_acno.$el)
        // this.fn_addValidErr(this.$refs.search.$refs.cal_baseDate.$el)
        /////

        if ( TSCommUtil.gfn_length(acctNo) < 13 ) //20100414
        {
          this.fn_addValidErr(this.$refs.search.$refs.Edt_acno.$el)
          TSCommUtil.gfn_validate(this, '계좌번호 입력이 정확하지 않았습니다.')
          return;
        }
        if ( TSCommUtil.gfn_isNull(this.ds_search.baseDate) ) //20100414
        {
          this.fn_addValidErr(this.$refs.search.$refs.cal_baseDate.$el)
          TSCommUtil.gfn_validate(this, '기준일자를 입력하십시오.')
          return;
        }
        
        let numBizDate = Number(this.gBizDate)
        let numBaseDate = Number (this.$refs.search.$refs.cal_baseDate.value.replaceAll("-",""))
        if( numBizDate <= numBaseDate ) {
          this.fn_addValidErr(this.$refs.search.$refs.cal_baseDate.$el)
          TSCommUtil.gfn_validate(this, '과거일자만 조회 가능합니다.')
          return;
        }

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600184_S'
        this.eaiCommObj.params = Object.assign({},this.ds_search)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
        
        /*서비스 호출시 정보 세팅 */
        // sSvcID = "selectTFSL80100List" ;
        // var sURL          = "svc::"+sSvcID+".do";	// svc = http://localhost:8080/
        // var sInDatasets   = "tfsl80100VO=ds_search:";    // transaction을 요청할 때 입력값으로 보낼 Dataset 의 ID 들 입니다.
        // var sOutDatasets  = "ds_list=tfsl80100List ds_detail=tfsl80100A";   // transaction을 처리 결과를 받을 Dataset의 ID 들입니다.
        // var sArgument     = "";
        // var sCallbackFunc = "fn_callBack"; // transaction의 결과를 돌려줄 Function의 이름입니다.
        
        // /*transaction을 호출하는 공통함수 */
        // this.ds_list.clearData();
        // this.ds_detail.clearData();	
        // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);
      },

      /**********************************************************************************
       * gfn_callService의 콜백 함수 : transaction 응답처리
       **********************************************************************************/
      fn_callBack ( res )
      {
        this.ds_list = []
        Object.assign(this.ds_detail, defaultDsDetail)
        Object.assign(this.ds_list_total, defaultDsList)

         // 응답코드가 정상이 아닌경우 
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          this.fn_AlertPopup(0,{content : resData.errorMsg}) 
          return false
        }

        Object.assign(this.ds_detail, res.data)
        
        this.ds_list = resData.tfsl80100
        // 포맷 변경
        this.ds_detail = Object.assign({}, resData)
        this.ds_detail.bizDate = this.$bizUtil.dateDashFormat(this.ds_detail.bizDate)
        this.ds_detail.contDate = this.$bizUtil.dateDashFormat(this.ds_detail.contDate)
        this.ds_detail.trstAmt = this.$bizUtil.numberWithCommas(Number(this.ds_detail.trstAmt))
        this.ds_detail.estmAmt = this.$bizUtil.numberWithCommas(Number(this.ds_detail.estmAmt)) 
        this.ds_detail.estmPlSum = this.$bizUtil.numberWithCommas(Number(this.ds_detail.estmPlSum)) 

        // $bizUtil.numberWithCommas(Number(ds_detail.estmPlSum))

        // this.ds_list.push(resData.tfsl80100[0]) // 테스트
        // 토탈 초기화 및 세팅
        Object.assign(this.ds_list_total, defaultDsList)
        for ( let idx in this.ds_list ) {
          this.ds_list_total.accbQty = Number(this.ds_list_total.accbQty) + Number(this.ds_list[idx].accbQty)
          this.ds_list_total.accbUpr = Number(this.ds_list_total.accbUpr) + Number(this.ds_list[idx].accbUpr)
          this.ds_list_total.accbAmt = Number(this.ds_list_total.accbAmt) + Number(this.ds_list[idx].accbAmt)
          this.ds_list_total.estmAmt = Number(this.ds_list_total.estmAmt) + Number(this.ds_list[idx].estmAmt)
          this.ds_list_total.consRto = Number(this.ds_list_total.consRto) + Number(this.ds_list[idx].consRto)
          this.ds_list_total.estmUpr = Number(this.ds_list_total.estmUpr) + Number(this.ds_list[idx].estmUpr)
        }
      }, 

      // /************************************************************************************************
      // - 그리드의 onheadclick 이벤트에 this.gfn_gridSort함수를 선언하여 사용한다.
      // - 컬럼을 지정하여 정렬처리할 경우, 아래와 같이 분기처리한다.
      //   예) if(e.col == 3 || e.col == 5 || e.col > 8)
      // - 데이터가 숫자인 경우, 데이터셋의 해당컬럼 타입이 number계열이여야 정상적으로 sort된다.
      // *************************************************************************************************/
      // this.grd_list_onheadclick = function(obj:Grid, e:nexacro.GridClickEventInfo)
      // {	
      //   this.gfn_gridSort(obj,e);
      // }

      // /***********************************************************************************
      //     보고서출력 
      // **********************************************************************************/
      // this.fn_print = function(obj:Button, e:nexacro.ClickEventInfo)
      // {
      //   //조회 데이터셋 row  초기화후 row추가 : row 없으면 입력 불가능.	
      //   var acctNo = "";
      //   acctNo = this.Div02.Div_acno._getTacnoValue()+this.Div02.Div_acno._getBnkbSeqValue()+this.Div02.Div_acno._getAccSeqValue();
        
      //   //조회용 dataset에 조회할 항목을 넣어준다.
      //   this.ds_search.setColumn(0, "tacno"		, this.Div02.Div_acno._getTacnoValue());
      //   this.ds_search.setColumn(0, "bnkbSeq"	, this.Div02.Div_acno._getBnkbSeqValue());
      //   this.ds_search.setColumn(0, "accSeq"	, this.Div02.Div_acno._getAccSeqValue());
      //   this.ds_search.setColumn(0, "cnm"		, this.Div02.Div_acno._getCnmValue());
      //   this.ds_search.setColumn(0, "acctNo"	, acctNo); // 계좌번호(13)
      //   this.ds_search.setColumn(0, "trstAmt"	, this.Div01.mae_trstAmt.value); // 계약금액(수탁원본)
      //   this.ds_search.setColumn(0, "bizDate"	, gBizDate); // 영업일자
      //   this.ds_search.setColumn(0, "baseDate"	, this.Div02.cal_baseDate.value); // 기준일자
        
      //   if ( this.gfn_length(acctNo) < 13 ) //20100414
      //   {
      //     this.alert("계좌번호 입력이 정확하지 않았습니다.");
      //     this.Div02.Div_acno.setFocus();
      //     return;
      //   }
        
      //   if ( this.gfn_isNull(this.Div02.cal_baseDate.value) ) //20100414
      //   {
      //     this.alert("기준일자를 입력하십시오.");
      //     this.Div02.cal_baseDate.setFocus();
      //     return;
      //   }	
        
      //   if( gBizDate <= this.Div02.cal_baseDate.value ) {
      //     this.alert("과거일자만 조회 가능합니다.");
      //     this.Div02.cal_baseDate.setFocus();
      //     return;
      //   }
        
      //   var reportMrdNm = "/cronix/fiduciary/support/TFSL80100.mrd"; // 보고서 파일 경로
      //   var reportUrl    = "reportTFSL80100List.do"; // 보고서 호출 서비스url
      //   var reportParam  = this.ds_search;
        
      //   //보고서 출력 함수
      //   this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam);

      // }

      // /************************************************************************************************
      // * 엑셀EXPORT
      // *************************************************************************************************/
      // // this.fn_excelDown = function(obj:Button, e:nexacro.ClickEventInfo)
      // // {
      // //     this.gfn_exportExcel(this.grd_list, "TFSL80100", "신탁운용 자산현황"); 
      // // }

      // /************************************************************************************************
      // * 재무확인서 출력
      // *************************************************************************************************/
      // this.Div02_btn_print1_onclick = function(obj:Button,  e:nexacro.ClickEventInfo)
      // {
      //   var acctNo = "";
      //   acctNo = this.Div02.Div_acno._getTacnoValue()+this.Div02.Div_acno._getBnkbSeqValue()+this.Div02.Div_acno._getAccSeqValue();
        
      //   if ( this.gfn_length(acctNo) < 13 ) //20100414
      //   {
      //     this.alert("계좌번호 입력이 정확하지 않았습니다.");
      //     this.Div02.Div_acno.setFocus();
      //     return;
      //   }
        
      //   if ( this.gfn_isNull(this.Div02.cal_baseDate.value) ) //20100414
      //   {
      //     this.alert("기준일자를 입력하십시오.");
      //     this.Div02.cal_baseDate.setFocus();
      //     return;
      //   }
        
      //   if( gBizDate <= this.Div02.cal_baseDate.value ) {
      //     this.alert("과거일자만 조회 가능합니다.");
      //     this.Div02.cal_baseDate.setFocus();
      //     return;
      //   }
        
      //   //조회용 dataset에 조회할 항목을 넣어준다.
      //   this.ds_search.setColumn(0, "tacno"		, this.Div02.Div_acno._getTacnoValue());
      //   this.ds_search.setColumn(0, "bnkbSeq"	, this.Div02.Div_acno._getBnkbSeqValue());
      //   this.ds_search.setColumn(0, "accSeq"	, this.Div02.Div_acno._getAccSeqValue());
      //   this.ds_search.setColumn(0, "cnm"		, this.Div02.Div_acno._getCnmValue());
      //   this.ds_search.setColumn(0, "acctNo"	, acctNo); // 계좌번호(13)
      //   this.ds_search.setColumn(0, "trstAmt"	, this.Div01.mae_trstAmt.value); // 계약금액(수탁원본)
      //   this.ds_search.setColumn(0, "bizDate"	, gBizDate); // 영업일자
      //   this.ds_search.setColumn(0, "baseDate"	, this.Div02.cal_baseDate.value); // 기준일자
        
        
      //   var reportMrdNm = "/cronix/fiduciary/support/TFSL80101.mrd"; // 보고서 파일 경로
      //   var reportUrl    = "reportTFSL80100.do"; // 보고서 호출 서비스url
      //   var reportParam  = this.ds_search;
        
      //   //보고서 출력 함수
      //   this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam);
      // }

      /******************************************************************************
       * Function명 : fn_addValidErr
       * 설명       : 에러 클래스 추가
      ******************************************************************************/      
      fn_addValidErr (lv_el) {
        if ( ! lv_el.classList.contains('error') ) {  // class에 erorr가 없을시만 추가
          lv_el.classList.add('error')
        }
        lv_el.focus()
      },

      /******************************************************************************
      * Function명 : fn_rmAllErrCss
      * 설명       : 해당 컴포넌트 error css 삭제
      ******************************************************************************/
      fn_rmAllErrCss () {
        for ( let idx in this.$refs ) {
          if ( this.$refs[idx] != undefined ) {
          this.$refs[idx].$el.classList.remove("error")
          }
        }
      },
      
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
    }
  }
</script>

<style lang="scss">
</style>

<style scoped>
</style>
