/*===============================================================================
    Date 관련 공통 함수
 ===============================================================================
 ● gfn_isDate          : 날짜에 대한 형식 체크
 ● gfn_isDate6         : 날짜 형태의 6자리 입력값 인지 체크하는 함수
 ● gfn_isDate8         : 날짜 형태의 8자리 입력값 인지 체크하는 함수
 ● gfn_getBusyDate     : 서버의 현재 영업일을 가져온다
 ● gfn_getPrevBusyDate : 서버의 전 영업일을 가져온다
 ● gfn_getNextBusyDate : 서버의 다음 영업일을 가져온다
 ● gfn_firstDate       : 해당월의 시작 날짜를 yyyyMMdd형태로 구하기
 ● gfn_lastDate        : 해당월의 마지막 날짜를 yyyyMMdd형태로 구하기
 ● gfn_lastDateNum     : 해당월의 마지막 날짜를 숫자로 구하기
 ● gfn_addDate         : 입력된 날자에 OffSet 으로 지정된 만큼의 일을 더한다.
 ● gfn_minusDate       : 입력된 날자에 OffSet 으로 지정된 만큼의 일을 뺀다.
 ● gfn_addMonth        : 입력된 날자에 OffSet 으로 지정된 만큼의 달을 더한다.
 ● gfn_minusMonth      : 입력된 날자에 OffSet 으로 지정된 만큼의 일을 뺀다.
 ● gfn_datetime        : MiPlatform에서 사용하던 Datetime형식으로 변환
 ● gfn_makeDate        : MiPlatform에서 사용하던 Datetime형식으로 변환
 ● gfn_getDiffDay      : 2개의 날짜간의 Day count
 ● gfn_diffMonth       : 두 월간의 차이 월수 계산
 ● gfn_getDay          : 입력된 날자로부터 요일을 구함
 ● gfn_getDayName      : 입력된 날자로부터 요일명을 구함
 ● gfn_strToDate       : String 타입의 형식을 날짜형식으로 변환
 ● gfn_isLeapYear      : 윤년여부 확인
 ● gfn_solar2Lunar     : 양력을 음력으로 변환해주는 함수 (처리가능 기간  1841 - 2043년)
 ● gfn_lunar2Solar     : 음력을 양력으로 변환해주는 함수 (처리가능 기간  1841 - 2043년)
 ● gfn_getHolidays     : 양력 nYear에 해당하는 년도의 법정 공휴일(양력) List 모두 구하기
 ● gfn_solarBase       : 각 월별 음력 기준 정보를 처리하는 함수(처리가능 기간  1841 - 2043년) 단, 내부에서 사용하는 함수임
 ***********************************************************************************
 *       수정일         이름         사유 
 ***********************************************************************************
 *     2017-01-03       구태훈     ASR161200855 [신탁부]EDW 계약 데이터 전송 배치 정정 外 개선사항, 일자계산 양편 -> 한편 계산으로 수정 
 * 		 2023-05-10				지재호		 공통화 
 */
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'

const TSComDate = {}

/*********************************************************************************************
 * Function   : gfn_isDate(sDate) 
 * Parameter  : sDate - 검사일자
 * Return     : 유효성반환 (날짜형식이 아닐경우 false)
 *
 * 날짜에 대한 형식 체크
*********************************************************************************************/
TSComDate.gfn_isDate = function (sDate)
{
	let stringWrapper = new String(sDate);
 	stringWrapper = stringWrapper.replace("/","").replace("/","");
 	stringWrapper = stringWrapper.replace("-","").replace("-","");
 	stringWrapper = stringWrapper.replace(".","").replace(".","");
	
	if (stringWrapper.toString().length !== 8) return false;
	
	let iMonth  = Math.floor(stringWrapper.slice(4,6), 10);
	let iDate   = Math.floor(stringWrapper.slice(6,8), 10);
	
	if (iMonth < 1 || iMonth > 12) return false;	
	if (iDate < 1 || iDate > TSComDate.gfn_lastDateNum(stringWrapper)) return false;	
	
	return true;
}

/*********************************************************************************************
 * Function   : gfn_isDate6(strValue) 
 * Parameter  : strValue - 검사일자
 * Return     : true = 입력값이 6자리 날짜(yyyyMMdd) 형태일 경우
 *
 * 입력값이 6자리 년월 형태 인지 체크하는 함수
*********************************************************************************************/
TSComDate.gfn_isDate6 = function (strValue)
{
	if (TSComDate.gfn_length(strValue) != 6){
		return false;
	} else if (!TSComDate.gfn_isDate(strValue + "01")){
		return false;
	}
	return true;
}

/*********************************************************************************************
 * Function   : gfn_isDate8(strValue) 
 * Parameter  : strValue - 검사일자
 * Return     : true = 입력값이 8자리 날짜(yyyyMMdd) 형태일 경우
 *
 * 입력값이 8자리 날짜 형태 인지 체크하는 함수
*********************************************************************************************/
TSComDate.gfn_isDate8 = function (strValue)
{
	if (strValue==null || strValue.length != 8){
		return false;
	} else if (!TSComDate.gfn_isDate(strValue)){
		return false;
	}
	return true;
}

/*********************************************************************************************
 * Function   : gfn_getBusyDate() 
 * Return     : 요청한 포맷으로 리턴
 *
 * 해당 서버의 영업일 날짜를 가져온다
*********************************************************************************************/
TSComDate.gfn_getBusyDate = function ()
{
	let data = window.vue.getStore('fsStore').getters.getBasInfo.data;
	return data.busyDate;
}

/*********************************************************************************************
 * Function   : gfn_getPrevBusyDate() 
 * Return     : 요청한 포맷으로 리턴
 *
 * 해당 서버의 전 영업일 날짜를 가져온다
*********************************************************************************************/
TSComDate.gfn_getPrevBusyDate = function ()
{
	let data = window.vue.getStore('fsStore').getters.getBasInfo.data;
	return data.prevBusyDate;
}

/*********************************************************************************************
 * Function   : gfn_getNextBusyDate() 
 * Return     : 요청한 포맷으로 리턴
 *
 * 해당 서버의 다음 영업일 날짜를 가져온다
*********************************************************************************************/
TSComDate.gfn_getNextBusyDate = function ()
{
	let data = window.vue.getStore('fsStore').getters.getBasInfo.data;
	return data.nextBusyDate;
}

/*********************************************************************************************
 * Function   : gfn_getServerTime(sType) 
 * Parameter  : sType -  yyyy,yy : 년도/  MM : 월/  dd : 일/  HH : 시간/  mm : 분/  ss : 초
 * Return     : 요청한 포맷으로 리턴
 *
 * 현재일시를 서버에서 가져온다
*********************************************************************************************/
TSComDate.gfn_getServerTime = function(sType)
{
	let sSvcID        = "getServerTime";
	let sURL          = "svc::rest/common/getServerTime";
	let sInDatasets   = "";
	let sOutDatasets  = "gds_servertime=data";
	let sArgument     = "dateType="+sType;
	let sCallbackFunc = "gfn_callBackReturn";
	let sTranType     = "S"; // U:저장/삭제, S:조회
	
	TSComDate.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc, sTranType, true);
	
	//서버시간저장
	return application.gds_servertime.getColumn(0,"CURRENT_TIME");
}

/*********************************************************************************************
 * Function   : gfn_firstDate(sDate) 
 * Parameter  : sDate - yyyyMMdd형태의 날짜 (예 : "20151122")
 * Return     : 성공 = yyyyMMdd형태의 시작 날짜 (예: "20151122" )
 *              실패 = ""
 *
 * 해당월의 시작 날짜를 yyyyMMdd형태로 구하기
*********************************************************************************************/
TSComDate.gfn_firstDate = function (sDate)
{ 
	if (TSCommUtil.gfn_isNull(sDate)){
		return "";
	}
	
	return sDate.substr(0, 6) + "01";
}

/*********************************************************************************************
 * Function   : gfn_lastDate(sDate) 
 * Parameter  : sDate - yyyyMMdd형태의 날짜 (예 : "20151122")
 * Return     : 성공 = yyyyMMdd형태의 마지막날짜 (예: "20151122" )
 *              실패 = ""
 *
 * 해당월의 마지막 날짜를 yyyyMMdd형태로 구하기
*********************************************************************************************/
TSComDate.gfn_lastDate = function (sDate)
{
	if (TSCommUtil.gfn_isNull(sDate)){
		return "";
	}
	
	let nLastDate = TSComDate.gfn_lastDateNum(sDate);
	return sDate.substr(0, 6) + nLastDate.toString();
}

/*********************************************************************************************
 * Function   : gfn_lastDate(sDate) 
 * Parameter  : sDate - yyyyMMdd형태의 날짜 (예 : "20151122")
 * Return     : 성공 = 마지막 날짜 숫자값 (예 : 30)
 *              실패 = -1
 *
 * 해당월의 마지막 날짜를 숫자로 구하기
*********************************************************************************************/
TSComDate.gfn_lastDateNum = function (sDate)
{
	let nMonth,nLastDate;

	if (TSCommUtil.gfn_isNull(sDate)){
		return -1;
	}

	nMonth = parseInt(sDate.substr(4, 2), 10);
	if (nMonth == 1 || nMonth == 3 || nMonth == 5 || nMonth == 7 || nMonth == 8 || nMonth == 10 || nMonth == 12){
		nLastDate = 31;
	} else if (nMonth == 2){
		if (TSComDate.gfn_isLeapYear(sDate) == true){
			nLastDate = 29;
		} else {
			nLastDate = 28;
		}
	} else {
		nLastDate = 30;
	}

	return nLastDate;
}

/*********************************************************************************************
 * Function   : gfn_addDate(date, nOffSet) 
 * Parameter  : date - yyyyMMdd 형태로 표현된 날자
 *              nOffSet - 날짜로부터 증가값. 
                          지정하지 않으면 Default Value = 1 로 적용됩니다.
 * Return     : Date에 nOffset이 더해진 결과를 'yyyyMMdd'로 표현된 날짜
 *
 * 입력된 날자에 OffSet 으로 지정된 만큼의 일을 더한다.(Date Type을 String으로 변환)
*********************************************************************************************/
TSComDate.gfn_addDate = function (date, nOffSet)
{
	let nYear = parseInt(date.substr(0, 4));
	let nMonth = parseInt(date.substr(4, 2));
	let nDate = parseInt(date.substr(6, 2)) + nOffSet;

	return TSComDate.gfn_datetime(nYear, nMonth, nDate, "date");
}

/*********************************************************************************************
 * Function   : gfn_minusDate(date, nOffSet) 
 * Parameter  : date - yyyyMMdd 형태로 표현된 날자
 *              nOffSet - 날짜로부터 감소값. 
                          지정하지 않으면 Default Value = 1 로 적용됩니다.
 * Return     : Date에 nOffset이 빼진 결과를 'yyyyMMdd'로 표현된 날짜
 *
 * 입력된 날자에 OffSet 으로 지정된 만큼의 일을 뺀다.(Date Type을 String으로 변환)
*********************************************************************************************/
TSComDate.gfn_minusDate = function (date, nOffSet)
{
	let nYear = parseInt(date.substr(0, 4));
	let nMonth = date.substr(4, 2);
	let nDate = parseInt(date.substr(6, 2)) - nOffSet;

	return TSComDate.gfn_datetime(nYear, nMonth, nDate, "date");
}

/*********************************************************************************************
 * Function   : gfn_addMonth(date, nOffSet) 
 * Parameter  : date - yyyyMMdd 형태로 표현된 날자
 *              nOffSet - 날짜로부터 증가값. 
                          지정하지 않으면 Default Value = 1 로 적용됩니다.
 * Return     : Date에 nOffset이 더해진 결과를 'yyyyMMdd'로 표현된 날짜
 *
 * 입력된 날자에 OffSet 으로 지정된 만큼의 달을 더한다.(Date Type을 String으로 변환)
*********************************************************************************************/
TSComDate.gfn_addMonth = function (date, nOffSet)
{
	let nYear = parseInt(date.substr(0, 4));
	let nMonth = parseInt(date.substr(4, 2)) + nOffSet;
	let nDate = parseInt(date.substr(6, 2));

	if (nMonth.toString().length == 1){
		nMonth = "0" + nMonth;
	}
		
	return TSComDate.gfn_datetime(nYear, nMonth, nDate,"month");
}

/*********************************************************************************************
 * Function   : gfn_minusMonth(date, nOffSet) 
 * Parameter  : date - yyyyMMdd 형태로 표현된 날자
 *              nOffSet - 날짜로부터 감소값. 
                          지정하지 않으면 Default Value = 1 로 적용됩니다.
 * Return     : Date에 nOffset이 빼진 결과를 'yyyyMMdd'로 표현된 날짜
 *
 * 입력된 날자에 OffSet 으로 지정된 만큼의 달을 뺀다.(Date Type을 String으로 변환)
*********************************************************************************************/
TSComDate.gfn_minusMonth = function (date, nOffSet)
{
	let nYear = parseInt(date.substr(0, 4));
	let nMonth = parseInt(date.substr(4, 2)) - nOffSet;
	let nDate = parseInt(date.substr(6, 2));
	
	if (nMonth.toString().length == 1){
		nMonth = "0" + nMonth;
	}
	
	return TSComDate.gfn_datetime(nYear, nMonth, nDate,"month");
}

/*********************************************************************************************
 * Function   : gfn_datetime(nYear, nMonth, nDate, sType) 
 * Parameter  : nYear  - 년도/ nMonth - 월/ nDate - 일                          
 * Return     : 조합한 날짜를 리턴.String
 *
 * MiPlatform에서 사용하던 Datetime형식으로 변환.(Date Type을 String으로 변환)
*********************************************************************************************/
TSComDate.gfn_datetime = function (nYear, nMonth, nDate, sType)
{
	let objDate = null

	if (nYear.toString().trim().length >= 5){
		let sDate = new String(nYear);
		let nYear = sDate.substr(0, 4);
		let nMonth = sDate.substr(4, 2);
		let nDate = ((sDate.substr(6, 2) == "") ? 1 : sDate.substr(6, 2));
		let nHours = ((sDate.substr(8, 2) == "") ? 0 : sDate.substr(8, 2));
		let nMinutes = ((sDate.substr(10, 2) == "") ? 0 : sDate.substr(10, 2));
		let nSeconds = ((sDate.substr(12, 2) == "") ? 0 : sDate.substr(12, 2));

		objDate = new Date(parseInt(nYear), parseInt(nMonth) - 1, parseInt(nDate), parseInt(nHours), parseInt(nMinutes), parseInt(nSeconds));
	} else {
		objDate = new Date( parseInt(nYear), parseInt(nMonth) - 1, parseInt(nDate == null ? 1 : nDate) );
	}
	
	let strYear = objDate.getFullYear().toString();
	let strMonth = (objDate.getMonth() + 1).toString();
	let strDate = objDate.getDate().toString();
	
	if (strMonth.length == 1){
		strMonth = "0" + strMonth;
	}
	if (strDate.length == 1){
		strDate = "0" + strDate;
	}	
	
	if(nDate != strDate && sType == "month" ) {
	
		strMonth = TSComDate.gfn_minusMonth(strYear + strMonth + strDate,1);
		strMonth = strMonth.substr(4, 2);
		
		if (strMonth.length == 1){
			strMonth = "0" + strMonth;
		}
		
		let strYYYYMMDD = TSComDate.gfn_lastDate(strYear+strMonth+strDate);
		
		strDate = parseInt(strYYYYMMDD.substr(6, 2));
	}

	return strYear + strMonth + strDate;
}

/*********************************************************************************************
 * Function   : gfn_makeDate(nYear, nMonth, nDate) 
 * Parameter  : nYear  - 년도/ nMonth - 월/ nDate - 일                          
 * Return     : 조합한 날짜를 리턴.String
 *
 * MiPlatform에서 사용하던 Datetime형식으로 변환.(Date Type을 String으로 변환)
*********************************************************************************************/
TSComDate.gfn_makeDate = function (nYear, nMonth, nDate)
{
	if (TSCommUtil.gfn_isNull(nYear) || TSCommUtil.gfn_isNull(nMonth) || TSCommUtil.gfn_isNull(nDate)){
		return "";
	}
	
	let objDate = new Date(nYear, nMonth - 1, nDate);
	let sYear  = objDate.getFullYear().toString();
	let sMonth = TSCommUtil.gfn_right("0" + (objDate.getMonth() + 1), 2);
	let sDate  = TSCommUtil.gfn_right("0" + objDate.getDate(), 2);
	
	return sYear + sMonth + sDate;
}

/*********************************************************************************************
 * Function   : gfn_getDiffDay(sFdate, sTdate) 
 * Parameter  : sFdate - 시작일자/ sTdate - 종료일자
 * Return     : 양 일자간의 Day count
 *
 * 2개의 날짜간의 Day count (예. gfn_getDiffDay("20150808", "20151001"))
 * 양편 -> 한변계산으로 변경 
 * (예 gfn_getDiffDay("20161229", "20161230") -> 1 로 계산 
*********************************************************************************************/
TSComDate.gfn_getDiffDay = function (sFdate, sTdate)
{
	sFdate = new String(sFdate);
	sFdate = sFdate.split(" ").join("").split("-").join("").split("/").join("");
	sTdate = new String(sTdate);
	sTdate = sTdate.split(" ").join("").split("-").join("").split("/").join("");
	
	sFdate = TSCommUtil.gfn_left(sFdate, 8);
	sTdate = TSCommUtil.gfn_left(sTdate, 8);
	
	// if (sFdate.length != 8 || sTdate.length != 8
	// 	 || nexacro.isNumeric(sFdate) == false || nexacro.isNumeric(sTdate) == false
	// 	 || TSComDate.gfn_getDay(sFdate) == -1 || TSComDate.gfn_getDay(sTdate) == -1){
	// 	return null;
	// }
	if ( sFdate.length != 8 || TSComDate.gfn_getDay(sFdate) == -1 ) {
		return null
	}
	if ( sTdate.length != 8 || TSComDate.gfn_getDay(sTdate) == -1 ) {
		return null
	}
	
	let nDiffDate = TSComDate.gfn_strToDate(sTdate) - TSComDate.gfn_strToDate(sFdate);
	
	let nDay = 1000 * 60 * 60 * 24;
// 	this.alert("1:" + nDiffDate);
 	nDiffDate = parseInt(nDiffDate / nDay);
// 	this.alert("2:" +nDiffDate);
// 	
// 	if (nDiffDate < 0){
// 		nDiffDate = nDiffDate ;
// 	} else {
// 		nDiffDate = nDiffDate ;
// 	}
// 	this.alert(nDiffDate);
	return nDiffDate;
}

/*********************************************************************************************
 * Function   : gfn_diffMonth(sStartDate, sEndDate) 
 * Parameter  : sStartDate - yyyyMMdd형태의 From일자/ sEndDate - yyyyMMdd형태의 To일자
 * Return     : 숫자 형태의 차이월수(단, sEndDate < sStartDate이면 음수가 return된다.)
 *
 * 두 월간의 차이 월수 계산(주의사항 sStartDate, sEndDate의 일은 포함하지 않고 계산된다.)
*********************************************************************************************/
TSComDate.gfn_diffMonth = function (sStartDate, sEndDate)
{
	let nStartMon,nEndMon;

	if (TSCommUtil.gfn_isNull(sStartDate) || TSCommUtil.gfn_isNull(sEndDate)){
		return NaN;
	}

	nStartMon = parseInt(sStartDate.toString().substr(0, 4), 10) * 12 + parseInt(sStartDate.toString().substr(4, 2), 10);
	nEndMon = parseInt(sEndDate.toString().substr(0, 4), 10) * 12 + parseInt(sEndDate.toString().substr(4, 2), 10);

	return (nEndMon - nStartMon);
}

/*********************************************************************************************
 * Function   : gfn_getDay(sDate) 
 * Parameter  : sDate - 8자리 형식으로된 날짜. yyyyMMdd의 형식으로 입력
 * Return     : 요일에 따른 숫자.
 *              0 = 일요일 ~ 6 = 토요일 로 대응.
 *              오류가 발생할 경우 -1
 *
 * 입력된 날자로부터 요일을 구함
*********************************************************************************************/
TSComDate.gfn_getDay = function (sDate)
{
	let objDate = TSComDate.gfn_strToDate(sDate);
	return objDate.getDay();
}

/*********************************************************************************************
 * Function   : gfn_getDayName(sDate) 
 * Parameter  : sDate - 8자리 형식으로된 날짜. yyyyMMdd의 형식으로 입력됩니다.
 * Return     : 요일명
 *
 * 입력된 날자로부터 요일명을 구함
*********************************************************************************************/
TSComDate.gfn_getDayName = function (sDate)
{
	let objDayName = new Array("일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일");
	let objDate = TSComDate.gfn_getDay(sDate);

	return objDayName[objDate];
}

/*********************************************************************************************
 * Function   : gfn_strToDate(sDate) 
 * Parameter  : sDate - 8자리 형식으로된 날짜. yyyyMMdd의 형식으로 입력됩니다.
 * Return     : Date
 *
 * String타입의 형식을 날짜형식으로 변환
*********************************************************************************************/
TSComDate.gfn_strToDate = function (sDate)
{
	let nYear = parseInt(sDate.substr(0, 4));
	let nMonth = parseInt(sDate.substr(4, 2)) - 1;
	let nDate = parseInt(sDate.substr(6, 2));

	return new Date(nYear, nMonth, nDate);
}

/*********************************************************************************************
 * Function   : gfn_isLeapYear(sDate) 
 * Parameter  : sDate - yyyyMMdd형태의 날짜(예:"20151122")
 * Return     : sDate가 윤년인 경우 = true
 *		        sDate가 윤년이 아닌 경우 = false
 *		        sDate가 입력되지 않은 경우 = false
 *
 * 윤년여부 확인
*********************************************************************************************/
TSComDate.gfn_isLeapYear = function (sDate)
{
	let ret;
	let nY;

	if (TSCommUtil.gfn_isNull(sDate)){
		return false;
	}

	nY = parseInt(sDate.substring(0, 4), 10);

	if ((nY % 4) == 0){
		if ((nY % 100) != 0 || (nY % 400) == 0){
			ret = true;
		} else {
			ret = false;
		}
	} else {
		ret = false;
	}

	return ret;
}

/*********************************************************************************************
 * Function   : gfn_solar2Lunar(sDate) 
 * Parameter  : sDate - yyyyMMdd형태의 날짜(예:"20151122")
 * Return     : return값이 8자리가 아니고 9자리임에 주의
 *		        성공 = Flag(1 Byte) + (yyyyMMdd형태의 음력일자)
 *				       (Flag : 평달 = "0", 윤달 = "1")
 *		        실패 = "" (1841 ~ 2043 범위 오류시)
 *
 * 양력을 음력으로 변환해주는 함수 (처리가능 기간  1841 - 2043년)
*********************************************************************************************/
TSComDate.gfn_solar2Lunar = function (sDate)
{
	let sMd = "31,0,31,30,31,30,31,31,30,31,30,31";
	let aMd = new Array();

	let aBaseInfo = new Array();
	let aDt = new Array();
	let td;
	let td1;
	let td2;
	let mm,m1,m2;
	let nLy,nLm,nLd;
	let sLyoon;
	if (TSCommUtil.gfn_isNull(sDate)){
		return "";
	}

	let sY = parseInt(sDate.substr(0, 4), 10);
	let sM = parseInt(sDate.substr(4, 2), 10);
	let sD = parseInt(sDate.substr(6, 2), 10);
	if (sY < 1841 || sY > 2043){
		return "";
	}

	aBaseInfo = TSComDate.gfn_solarBase();
	aMd = sMd.split(",");
	if (TSComDate.gfn_isLeapYear(sDate) == true){
		aMd[1] = 29;
	} else {
		aMd[1] = 28;
	}

	td1 = 672069; //672069 = 1840 * 365 + 1840/4 - 1840/100 + 1840/400 + 23  //1840년까지 날수

	// 1841년부터 작년까지의 날수
	td2 = (sY - 1) * 365 + parseInt((sY - 1) / 4) - parseInt((sY - 1) / 100) + parseInt((sY - 1) / 400);

	// 전월까지의 날수를 더함
	for (let i=0; i <= sM - 2; i++){
		td2 = td2 + parseInt(aMd[i]);
	}

	// 현재일까지의 날수를 더함
	td2 = td2 + sD;

	// 양력현재일과 음력 1840년까지의 날수의 차이
	td = td2 - td1 + 1;

	// 1841년부터 음력날수를 계산
	for (let i=0; i<=sY-1841; i++){
		aDt[i] = 0;
		for (j=0; j<=11; j++){
			switch (parseInt(aBaseInfo[i*12 + j])){
				case 1: 
					mm = 29;
					break;
				case 2:
					mm = 30;
					break;				
				case 3:
					mm = 58;	// 29 + 29
					break;				
				case 4:
					mm = 59;	// 29 + 30
					break;				
				case 5:
					mm = 59;	// 30 + 29
					break;				
				case 6:
					mm = 60;	// 30 + 30
					break;				
			}
			aDt[i] = aDt[i] + mm;
		}
	}

	// 1840년 이후의 년도를 계산 - 현재까지의 일수에서 위에서 계산된 1841년부터의 매년 음력일수를 빼가면수 년도를 계산
	nLy = 0;
	do{
		td = td - aDt[nLy];
		nLy = nLy + 1;
	} while (td > aDt[nLy]);

	nLm = 0;
	sLyoon = "0"; //현재월이 윤달임을 표시할 변수 - 기본값 평달
	do{
		if (parseInt(aBaseInfo[nLy * 12 + nLm]) <= 2){
			mm = parseInt(aBaseInfo[nLy * 12 + nLm]) + 28;
			if (td > mm){
				td = td - mm;
				nLm = nLm + 1;
			} else {
				break;
			}
		} else {
			switch (parseInt(aBaseInfo[nLy * 12 + nLm])){
				case 3:
					m1 = 29;
					m2 = 29;
					break;
				case 4:
					m1 = 29;
					m2 = 30;
					break;
				case 5:
					m1 = 30;
					m2 = 29;
					break;
				case 6:
					m1 = 30;
					m2 = 30;
					break;
			}

			if (td > m1){
				td = td - m1;
				if (td > m2){
					td = td - m2;
					nLm = nLm + 1;
				} else {
					sLyoon = "1";
				}
			} else {
				break;
			}
		}
	} while (1);
	
	nLy = nLy + 1841;
	nLm = nLm + 1;
	nLd = td;
	
	return sLyoon + nLy + TSComDate.gfn_right("0" + nLm, 2) + TSComDate.gfn_right("0" + nLd, 2);
}

/*********************************************************************************************
 * Function   : gfn_lunar2Solar(sDate) 
 * Parameter  : sDate - Flag(1 Byte)+yyyyMMdd형태의 음력일자 ( 예 : "020151122" ) 
 *              (Flag : 평달 = "0", 윤달 = "1" )
 * Return     : 성공 = yyyyMMdd형태의 양력일자
 *				       (Flag : 평달 = "0", 윤달 = "1")
 *		        실패 = null
 *				       1841 ~ 2043 범위 오류의 경우
 *				       sDate가 9자리가 아닐경우
 *				       sDate의 첫자리 Flag가 "0"도 아니고 "1"도 아닌 경우
 *
 * 음력을 양력으로 변환해주는 함수 (처리가능 기간  1841 - 2043년)
*********************************************************************************************/
TSComDate.gfn_lunar2Solar = function (sDate)
{
	let sMd = "31,0,31,30,31,30,31,31,30,31,30,31";
	let aMd = new Array();	
	let aBaseInfo = new Array();	
	
	let nLy, nLm, nLd, sLflag;		// 전해온 음력 인자값을 저장할 년, 월, 일, 윤달여부 임시변수
	let nSy, nSm, nSd;				// 계산된 양력 년, 월, 일을 저장할 변수
	let y1, m1, i, j, y2, y3;	// 임시변수	
	let leap;

	if (TSCommUtil.gfn_isNull(sDate))	return "";
	if (sDate.length != 9)		return "";
	
	sLflag = sDate.substr(0,1);
	nLy = parseInt(sDate.substr(1,4), 10);
	nLm = parseInt(sDate.substr(5,2), 10);
	nLd = parseInt(sDate.substr(7,2), 10);
	if (nLy < 1841 || nLy > 2043)			return "";
	if (sLflag != "0" && sLflag != "1")	return "";
		
	aBaseInfo = TSComDate.gfn_solarBase();
	aMd = sMd.split(",");
	if (TSComDate.gfn_isLeapYear(sDate.substr(1,8)) == true){				
		aMd[1] = 29;
	} else {
		aMd[1] = 28;	
	}
	y1 = nLy - 1841;
	m1 = nLm - 1;
	leap = 0;
	if (parseInt(aBaseInfo[y1*12 + m1]) > 2){
		leap = TSComDate.gfn_isLeapYear(nLy+"0101");
	}
	if (leap == 1){
		switch (parseInt(aBaseInfo[y1*12 + m1])){
			case 3:
				mm = 29;
				break;
			case 4:
				mm = 30;
				break;			
			case 5:
				mm = 29;
				break;			
			case 6:
				mm = 30;
				break;
		}
	} else {
		switch (parseInt(aBaseInfo[y1*12 + m1])){
			case 1:
				mm = 29;
				break;			
			case 2:
				mm = 30;
				break;			
			case 3:
				mm = 29;
				break;			
			case 4:
				mm = 29;
				break;			
			case 5:
				mm = 30;
				break;			
			case 6:
				mm = 30;
				break;			
		}
	}

	td = 0;
	for (i=0; i<=y1-1; i++){
		for(j=0; j<=11; j++){
			switch (parseInt(aBaseInfo[i*12+j])){
				case 1:
					td = td + 29;
					break;
				case 2:
					td = td + 30;
					break;				
				case 3:
					td = td + 58;
					break;				
				case 4:
					td = td + 59;
					break;				
				case 5: 
					td = td + 59;
					break;				
				case 6:
					td = td + 60;
					break;				
			}
		}
	}

	for (j=0; j<=m1-1; j++){
		switch (parseInt(aBaseInfo[y1*12+j])){
			case 1:
				td = td + 29;
				break;			
			case 2:
				td = td + 30;
				break;						
			case 3:
				td = td + 58;
				break;						
			case 4:
				td = td + 59;
				break;						
			case 5:
				td = td + 59;
				break;						
			case 6:
				td = td + 60;
				break;						
		}
	}

	if (leap == 1){
		switch (parseInt(aBaseInfo[y1*12+m1])){
			case 3:
				mm = 29;
				break;						
			case 4:
				mm = 29;
				break;						
			case 5:
				mm = 30;
				break;						
			case 6:
				mm = 30;
				break;						
		}
	}
	
	td = td + nLd + 22;
	if (sLflag == "1"){
		switch (parseInt(aBaseInfo[y1*12+m1])){
			case 3:
				td = td + 29;
				break;						
			case 4:
				td = td + 30;
				break;						
			case 5:
				td = td + 29;
				break;						
			case 6:
				td = td + 30;
				break;						
		}
	}
	
	y1 = 1840;
	do{
		y1 = y1 + 1;
		leap = TSComDate.gfn_isLeapYear(y1+"0101");

		if (leap == 1){
			y2 = 366;
		} else {
			y2 = 365;
		}
		if (td <= y2){
			break;
		}
		td = td - y2;
	} while(1);

	nSy = y1;
	aMd[1] = y2 - 337;
	m1 = 0;
	do{
		m1 = m1 + 1;
		if (td <= parseInt(aMd[m1-1])){
			break;
		}
		td = td - parseInt(aMd[m1-1]);
	} while(1);
	
	nSm = m1;
	nSd = td;
	y3 = nSy;
	td = y3 * 365 + parseInt(y3/4) - parseInt(y3/100) + parseInt(y3/400);
	for (i=0; i<=nSm-1; i++){
		td = td + parseInt(aMd[i]);
	}
	td = td + nSd;
	
	return y3 + TSComDate.gfn_right("0" + nSm, 2)+TSComDate.gfn_right("0" + nSd, 2);
}

/*********************************************************************************************
 * Function   : gfn_getHolidays(nYear) 
 * Parameter  : nYear - nYear에 해당하는 년도 (예 : 2015)
 *              (Flag : 평달 = "0", 윤달 = "1" )
 * Return     : 성공 = 공휴일 List Array ==> 각 Array[i]="yyyyMMdd공휴일명" 으로 return된다.
 *				        (예 : Array[0] = "20150101신정")
 *		        실패 = 빈 Array
 *
 * 양력 nYear에 해당하는 년도의 법정 공휴일(양력) List 모두 구하기
*********************************************************************************************/
TSComDate.gfn_getHolidays = function (nYear)
{
	let aHoliday = new Array();

	if (TSCommUtil.gfn_isNull(nYear)){
		return aHoliday;
	}

	// ///// 음력 체크
	// 구정
	aHoliday[0] = TSComDate.gfn_lunar2Solar("0" + (nYear - 1) + "1230") + "설날";
	aHoliday[1] = TSComDate.gfn_addDate(aHoliday[0], 1) + "설날";
	aHoliday[2] = TSComDate.gfn_addDate(aHoliday[1], 1) + "설날";
	// 석가탄신일
	aHoliday[3] = TSComDate.gfn_lunar2Solar("0" + nYear + "0408") + "석가탄신일";
	// 추석
	aHoliday[4] = TSComDate.gfn_lunar2Solar("0" + nYear + "0814") + "추석";
	aHoliday[5] = TSComDate.gfn_addDate(aHoliday[4], 1) + "추석";
	aHoliday[6] = TSComDate.gfn_addDate(aHoliday[5], 1) + "추석";
	
	// ///// 양력 체크
	aHoliday[7] = nYear + "0101" + "신정";
	aHoliday[8] = nYear + "0301" + "삼일절";
	aHoliday[9] = nYear + "0505" + "어린이날";
	aHoliday[10] = nYear + "0606" + "현충일";
	aHoliday[11] = nYear + "0815" + "광복절";
	aHoliday[12] = nYear + "1003" + "개천절";
	aHoliday[13] = nYear + "1009" + "한글날";
	aHoliday[14] = nYear + "1225" + "성탄절";

	return aHoliday.sort();
}

/*********************************************************************************************
 * Function   : gfn_solarBase
 * Parameter  : none
 * Return     : 성공 = 음력 기준정보
 *
 * 각 월별 음력 기준 정보를 처리하는 함수(처리가능 기간  1841 - 2043년) 단, 내부에서 사용하는 함수임
*********************************************************************************************/
TSComDate.gfn_solarBase = function ()
{
	let kk;

	// 1841
	kk = "1,2,4,1,1,2,1,2,1,2,2,1,";
	kk += "2,2,1,2,1,1,2,1,2,1,2,1,";
	kk += "2,2,2,1,2,1,4,1,2,1,2,1,";
	kk += "2,2,1,2,1,2,1,2,1,2,1,2,";
	kk += "1,2,1,2,2,1,2,1,2,1,2,1,";
	kk += "2,1,2,1,5,2,1,2,2,1,2,1,";
	kk += "2,1,1,2,1,2,1,2,2,2,1,2,";
	kk += "1,2,1,1,2,1,2,1,2,2,2,1,";
	kk += "2,1,2,3,2,1,2,1,2,1,2,2,";
	kk += "2,1,2,1,1,2,1,1,2,2,1,2,";
	// 1851
	kk += "2,2,1,2,1,1,2,1,2,1,5,2,";
	kk += "2,1,2,2,1,1,2,1,2,1,1,2,";
	kk += "2,1,2,2,1,2,1,2,1,2,1,2,";
	kk += "1,2,1,2,1,2,5,2,1,2,1,2,";
	kk += "1,1,2,1,2,2,1,2,2,1,2,1,";
	kk += "2,1,1,2,1,2,1,2,2,2,1,2,";
	kk += "1,2,1,1,5,2,1,2,1,2,2,2,";
	kk += "1,2,1,1,2,1,1,2,2,1,2,2,";
	kk += "2,1,2,1,1,2,1,1,2,1,2,2,";
	kk += "2,1,6,1,1,2,1,1,2,1,2,2,";
	// 1861
	kk += "1,2,2,1,2,1,2,1,2,1,1,2,";
	kk += "2,1,2,1,2,2,1,2,2,3,1,2,";
	kk += "1,2,2,1,2,1,2,2,1,2,1,2,";
	kk += "1,1,2,1,2,1,2,2,1,2,2,1,";
	kk += "2,1,1,2,4,1,2,2,1,2,2,1,";
	kk += "2,1,1,2,1,1,2,2,1,2,2,2,";
	kk += "1,2,1,1,2,1,1,2,1,2,2,2,";
	kk += "1,2,2,3,2,1,1,2,1,2,2,1,";
	kk += "2,2,2,1,1,2,1,1,2,1,2,1,";
	kk += "2,2,2,1,2,1,2,1,1,5,2,1,";
	// 1871
	kk += "2,2,1,2,2,1,2,1,2,1,1,2,";
	kk += "1,2,1,2,2,1,2,1,2,2,1,2,";
	kk += "1,1,2,1,2,4,2,1,2,2,1,2,";
	kk += "1,1,2,1,2,1,2,1,2,2,2,1,";
	kk += "2,1,1,2,1,1,2,1,2,2,2,1,";
	kk += "2,2,1,1,5,1,2,1,2,2,1,2,";
	kk += "2,2,1,1,2,1,1,2,1,2,1,2,";
	kk += "2,2,1,2,1,2,1,1,2,1,2,1,";
	kk += "2,2,4,2,1,2,1,1,2,1,2,1,";
	kk += "2,1,2,2,1,2,2,1,2,1,1,2,";
	// 1881
	kk += "1,2,1,2,1,2,5,2,2,1,2,1,";
	kk += "1,2,1,2,1,2,1,2,2,1,2,2,";
	kk += "1,1,2,1,1,2,1,2,2,2,1,2,";
	kk += "2,1,1,2,3,2,1,2,2,1,2,2,";
	kk += "2,1,1,2,1,1,2,1,2,1,2,2,";
	kk += "2,1,2,1,2,1,1,2,1,2,1,2,";
	kk += "2,2,1,5,2,1,1,2,1,2,1,2,";
	kk += "2,1,2,2,1,2,1,1,2,1,2,1,";
	kk += "2,1,2,2,1,2,1,2,1,2,1,2,";
	kk += "1,5,2,1,2,2,1,2,1,2,1,2,";
	// 1891
	kk += "1,2,1,2,1,2,1,2,2,1,2,2,";
	kk += "1,1,2,1,1,5,2,2,1,2,2,2,";
	kk += "1,1,2,1,1,2,1,2,1,2,2,2,";
	kk += "1,2,1,2,1,1,2,1,2,1,2,2,";
	kk += "2,1,2,1,5,1,2,1,2,1,2,1,";
	kk += "2,2,2,1,2,1,1,2,1,2,1,2,";
	kk += "1,2,2,1,2,1,2,1,2,1,2,1,";
	kk += "2,1,5,2,2,1,2,1,2,1,2,1,";
	kk += "2,1,2,1,2,1,2,2,1,2,1,2,";
	kk += "1,2,1,1,2,1,2,5,2,2,1,2,";
	// 1901
	kk += "1,2,1,1,2,1,2,1,2,2,2,1,";
	kk += "2,1,2,1,1,2,1,2,1,2,2,2,";
	kk += "1,2,1,2,3,2,1,1,2,2,1,2,";
	kk += "2,2,1,2,1,1,2,1,1,2,2,1,";
	kk += "2,2,1,2,2,1,1,2,1,2,1,2,";
	kk += "1,2,2,4,1,2,1,2,1,2,1,2,";
	kk += "1,2,1,2,1,2,2,1,2,1,2,1,";
	kk += "2,1,1,2,2,1,2,1,2,2,1,2,";
	kk += "1,5,1,2,1,2,1,2,2,2,1,2,";
	kk += "1,2,1,1,2,1,2,1,2,2,2,1,";
	// 1911
	kk += "2,1,2,1,1,5,1,2,2,1,2,2,";
	kk += "2,1,2,1,1,2,1,1,2,2,1,2,";
	kk += "2,2,1,2,1,1,2,1,1,2,1,2,";
	kk += "2,2,1,2,5,1,2,1,2,1,1,2,";
	kk += "2,1,2,2,1,2,1,2,1,2,1,2,";
	kk += "1,2,1,2,1,2,2,1,2,1,2,1,";
	kk += "2,3,2,1,2,2,1,2,2,1,2,1,";
	kk += "2,1,1,2,1,2,1,2,2,2,1,2,";
	kk += "1,2,1,1,2,1,5,2,2,1,2,2,";
	kk += "1,2,1,1,2,1,1,2,2,1,2,2,";
	// 1921
	kk += "2,1,2,1,1,2,1,1,2,1,2,2,";
	kk += "2,1,2,2,3,2,1,1,2,1,2,2,";
	kk += "1,2,2,1,2,1,2,1,2,1,1,2,";
	kk += "2,1,2,1,2,2,1,2,1,2,1,1,";
	kk += "2,1,2,5,2,1,2,2,1,2,1,2,";
	kk += "1,1,2,1,2,1,2,2,1,2,2,1,";
	kk += "2,1,1,2,1,2,1,2,2,1,2,2,";
	kk += "1,5,1,2,1,1,2,2,1,2,2,2,";
	kk += "1,2,1,1,2,1,1,2,1,2,2,2,";
	kk += "1,2,2,1,1,5,1,2,1,2,2,1,";
	// 1931
	kk += "2,2,2,1,1,2,1,1,2,1,2,1,";
	kk += "2,2,2,1,2,1,2,1,1,2,1,2,";
	kk += "1,2,2,1,6,1,2,1,2,1,1,2,";
	kk += "1,2,1,2,2,1,2,2,1,2,1,2,";
	kk += "1,1,2,1,2,1,2,2,1,2,2,1,";
	kk += "2,1,4,1,2,1,2,1,2,2,2,1,";
	kk += "2,1,1,2,1,1,2,1,2,2,2,1,";
	kk += "2,2,1,1,2,1,4,1,2,2,1,2,";
	kk += "2,2,1,1,2,1,1,2,1,2,1,2,";
	kk += "2,2,1,2,1,2,1,1,2,1,2,1,";
	// 1941
	kk += "2,2,1,2,2,4,1,1,2,1,2,1,";
	kk += "2,1,2,2,1,2,2,1,2,1,1,2,";
	kk += "1,2,1,2,1,2,2,1,2,2,1,2,";
	kk += "1,1,2,4,1,2,1,2,2,1,2,2,";
	kk += "1,1,2,1,1,2,1,2,2,2,1,2,";
	kk += "2,1,1,2,1,1,2,1,2,2,1,2,";
	kk += "2,5,1,2,1,1,2,1,2,1,2,2,";
	kk += "2,1,2,1,2,1,1,2,1,2,1,2,";
	kk += "2,2,1,2,1,2,3,2,1,2,1,2,";
	kk += "2,1,2,2,1,2,1,1,2,1,2,1,";
	// 1951
	kk += "2,1,2,2,1,2,1,2,1,2,1,2,";
	kk += "1,2,1,2,4,2,1,2,1,2,1,2,";
	kk += "1,2,1,1,2,2,1,2,2,1,2,2,";
	kk += "1,1,2,1,1,2,1,2,2,1,2,2,";
	kk += "2,1,4,1,1,2,1,2,1,2,2,2,";
	kk += "1,2,1,2,1,1,2,1,2,1,2,2,";
	kk += "2,1,2,1,2,1,1,5,2,1,2,2,";
	kk += "1,2,2,1,2,1,1,2,1,2,1,2,";
	kk += "1,2,2,1,2,1,2,1,2,1,2,1,";
	kk += "2,1,2,1,2,5,2,1,2,1,2,1,";
	// 1961
	kk += "2,1,2,1,2,1,2,2,1,2,1,2,";
	kk += "1,2,1,1,2,1,2,2,1,2,2,1,";
	kk += "2,1,2,3,2,1,2,1,2,2,2,1,";
	kk += "2,1,2,1,1,2,1,2,1,2,2,2,";
	kk += "1,2,1,2,1,1,2,1,1,2,2,1,";
	kk += "2,2,5,2,1,1,2,1,1,2,2,1,";
	kk += "2,2,1,2,2,1,1,2,1,2,1,2,";
	kk += "1,2,2,1,2,1,5,2,1,2,1,2,";
	kk += "1,2,1,2,1,2,2,1,2,1,2,1,";
	kk += "2,1,1,2,2,1,2,1,2,2,1,2,";
	// 1971
	kk += "1,2,1,1,5,2,1,2,2,2,1,2,";
	kk += "1,2,1,1,2,1,2,1,2,2,2,1,";
	kk += "2,1,2,1,1,2,1,1,2,2,2,1,";
	kk += "2,2,1,5,1,2,1,1,2,2,1,2,";
	kk += "2,2,1,2,1,1,2,1,1,2,1,2,";
	kk += "2,2,1,2,1,2,1,5,2,1,1,2,";
	kk += "2,1,2,2,1,2,1,2,1,2,1,1,";
	kk += "2,2,1,2,1,2,2,1,2,1,2,1,";
	kk += "2,1,1,2,1,6,1,2,2,1,2,1,";
	kk += "2,1,1,2,1,2,1,2,2,1,2,2,";
	// 1981
	kk += "1,2,1,1,2,1,1,2,2,1,2,2,";
	kk += "2,1,2,3,2,1,1,2,2,1,2,2,";
	kk += "2,1,2,1,1,2,1,1,2,1,2,2,";
	kk += "2,1,2,2,1,1,2,1,1,5,2,2,";
	kk += "1,2,2,1,2,1,2,1,1,2,1,2,";
	kk += "1,2,2,1,2,2,1,2,1,2,1,1,";
	kk += "2,1,2,2,1,5,2,2,1,2,1,2,";
	kk += "1,1,2,1,2,1,2,2,1,2,2,1,";
	kk += "2,1,1,2,1,2,1,2,2,1,2,2,";
	kk += "1,2,1,1,5,1,2,1,2,2,2,2,";
	// 1991
	kk += "1,2,1,1,2,1,1,2,1,2,2,2,";
	kk += "1,2,2,1,1,2,1,1,2,1,2,2,";
	kk += "1,2,5,2,1,2,1,1,2,1,2,1,";
	kk += "2,2,2,1,2,1,2,1,1,2,1,2,";
	kk += "1,2,2,1,2,2,1,5,2,1,1,2,";
	kk += "1,2,1,2,2,1,2,1,2,2,1,2,";
	kk += "1,1,2,1,2,1,2,2,1,2,2,1,";
	kk += "2,1,1,2,3,2,2,1,2,2,2,1,";
	kk += "2,1,1,2,1,1,2,1,2,2,2,1,";
	kk += "2,2,1,1,2,1,1,2,1,2,2,1,";
	// 2001
	kk += "2,2,2,3,2,1,1,2,1,2,1,2,";
	kk += "2,2,1,2,1,2,1,1,2,1,2,1,";
	kk += "2,2,1,2,2,1,2,1,1,2,1,2,";
	kk += "1,5,2,2,1,2,1,2,2,1,1,2,";
	kk += "1,2,1,2,1,2,2,1,2,2,1,2,";
	kk += "1,1,2,1,2,1,5,2,2,1,2,2,";
	kk += "1,1,2,1,1,2,1,2,2,2,1,2,";
	kk += "2,1,1,2,1,1,2,1,2,2,1,2,";
	kk += "2,2,1,1,5,1,2,1,2,1,2,2,";
	kk += "2,1,2,1,2,1,1,2,1,2,1,2,";
	// 2011
	kk += "2,1,2,2,1,2,1,1,2,1,2,1,";
	kk += "2,1,6,2,1,2,1,1,2,1,2,1,";
	kk += "2,1,2,2,1,2,1,2,1,2,1,2,";
	kk += "1,2,1,2,1,2,1,2,5,2,1,2,";
	kk += "1,2,1,1,2,1,2,2,2,1,2,2,";
	kk += "1,1,2,1,1,2,1,2,2,1,2,2,";
	kk += "2,1,1,2,3,2,1,2,1,2,2,2,";
	kk += "1,2,1,2,1,1,2,1,2,1,2,2,";
	kk += "2,1,2,1,2,1,1,2,1,2,1,2,";
	kk += "2,1,2,5,2,1,1,2,1,2,1,2,";
	// 2021
	kk += "1,2,2,1,2,1,2,1,2,1,2,1,";
	kk += "2,1,2,1,2,2,1,2,1,2,1,2,";
	kk += "1,5,2,1,2,1,2,2,1,2,1,2,";
	kk += "1,2,1,1,2,1,2,2,1,2,2,1,";
	kk += "2,1,2,1,1,5,2,1,2,2,2,1,";
	kk += "2,1,2,1,1,2,1,2,1,2,2,2,";
	kk += "1,2,1,2,1,1,2,1,1,2,2,2,";
	kk += "1,2,2,1,5,1,2,1,1,2,2,1,";
	kk += "2,2,1,2,2,1,1,2,1,1,2,2,";
	kk += "1,2,1,2,2,1,2,1,2,1,2,1,";
	// 2031
	kk += "2,1,5,2,1,2,2,1,2,1,2,1,";
	kk += "2,1,1,2,1,2,2,1,2,2,1,2,";
	kk += "1,2,1,1,2,1,5,2,2,2,1,2,";
	kk += "1,2,1,1,2,1,2,1,2,2,2,1,";
	kk += "2,1,2,1,1,2,1,1,2,2,1,2,";
	kk += "2,2,1,2,1,4,1,1,2,1,2,2,";
	kk += "2,2,1,2,1,1,2,1,1,2,1,2,";
	kk += "2,2,1,2,1,2,1,2,1,1,2,1,";
	kk += "2,2,1,2,5,2,1,2,1,2,1,1,";
	kk += "2,1,2,2,1,2,2,1,2,1,2,1,";
	// 2041
	kk += "2,1,1,2,1,2,2,1,2,2,1,2,";
	kk += "1,5,1,2,1,2,1,2,2,2,1,2,";
	kk += "1,2,1,1,2,1,1,2,2,1,2,2";

	let arr = new Array();
	arr = kk.split(",");

	return arr;
}

/**************************************************************************************************************
 * Function   : gfn_checkDate10
 * Parameter  : strValue(String)
 * Return     : true - 입력값이 10자리 날짜 형태일 경우/ 허용입력타입(yyyy-MM-dd, yyyy/MM/dd, yyyy.MM.dd)
 * 
 * 입력값이 날짜 형태인지 체크하는 함수(10자리)
 ***************************************************************************************************************/  
TSComDate.gfn_checkDate10 = function (strValue)
{
	return TSComDate.gfn_checkDate8(TSComDate.gfn_replace(TSComDate.gfn_replace(TSComDate.gfn_replace(strValue, "/", ""), "-", ""), ".", ""));
}

TSComDate.gfn_checkDate8 = function (strValue)
{
	if (TSComDate.gfn_length(strValue) != 8){
		return false;
	} else if (!TSComDate.gfn_DateCheck(strValue)){
		return false;
	}
	return true;
}

export default TSComDate
// eslint-disable-next-line