/*
* 업무구분: 계좌번호 콤보화면
* 화 면 명: MSPTS95090D
* 화면설명: 계좌번호 콤보화면
* 작 성 일: 2023.05.10
* 작 성 자: 지재호 
* 파일명 : TPUP95090.xfdl
*/
<template>
  <ur-box-container direction="column" alignV="start">
    <div class="input-top">
      <div class="column w100p gap12">
        <div class="row w100p">
          <div class="left">
            <div class="wrap-input row">
              <label class="emphasis"> 계좌번호 </label>
              <mo-decimal-field class="w130 input-account" 
                mask="#######-###"
                numeric 
                @keyup="Edt_acno_onkeyup($event, 'Edt_acno')"
                v-model="Edt_acno"
                ref="Edt_acno"
                :class="{'comp': Edt_acno.length > 9}"
                clearable
              />

              <!-- v-if="visibleList.Cbo_accSeq==true" -->
              <mo-dropdown class="w260" 
                :items="list_Cbo_accSeq" 
                v-model="Cbo_accSeq" 
                placeholder=" "
              />
              <mo-button class="btn-pop-download" @click="fn_OpenPopup(0)"> </mo-button>
              <mo-text-field class="w130" v-model="edt_cnm" disabled />
            </div>
            
          </div>
        </div>
        <div class="row w100p">
          <div class="left w100p">

            <div class="wrap-input row" v-if="dSearchObj.m_id == 'MSPTS110M'" >
              <label class="w50 emphasis"> 기간 </label>
              <div class="wrap-input row">
                <mo-date-picker v-model="dSearchObj.cal_from" :bottom="false" class="w130" ref="cal_from" /> ~
                <mo-date-picker v-model="dSearchObj.cal_to" :bottom="false" class="w130" ref="cal_to"  /> 
              </div>
            </div>

            <div class="wrap-input row flex-unset" v-if="dSearchObj.m_id == 'MSPTS102M'" >
              <label class="emphasis"> 기준일자 </label>
              <div class="wrap-input row">
                <mo-date-picker v-model="dSearchObj.cal_baseDate" :bottom="false" class="w130" ref="cal_baseDate" />
              </div>
            </div>

          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button @click="fn_init"> 초기화 </mo-button>
              <mo-button primary @click="fn_searchList"> 조회 </mo-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>
    
    <!-- popup206 계좌번호 조회 --> 
    <msp-ts-206p
      ref="popup206"
      @ts-popup206-callback="fn_Popup_CallBack"      
      :popupObj="pPopup206Obj"
    ></msp-ts-206p>

  </ur-box-container>
</template>
<script>
  /***********************************************************************************
   * DataSet 영역	                                                                    *
   ***********************************************************************************/
  const defaultDatasetTemp = {  
    tacno: '',
    bnkbSeq: '',
    accSeq: '',
    gdC: '',
    gdNm: '',
    trstTypC: '',
    cnm: '',
    rcno: '',
    rcnoKindTc: '',
    accStatC: '',
    bnkbPswd: '',
  }

  const defaultDsSearch = { 
    tacno: '',
    bnkbSeq: '',
    accStatC: '',
    brigAccYn: '',
  }

  const defaultDsList = { 
    tacno: '',
    bnkbSeq: '',
    accSeq: '',
    accStatC: '',
    gdNm: '',
    cnm: '',
    brigAccYn: '',
    curC: '',
    contDate: '',
    mtrtDate: '',
    trstTypC: '',
    gdC: '',
    trstAmt: '',
    gdTypDtlC: '',
    mngBrcd: '',
    csId: '',
    admkNm: '',
    bnkb: '',
    acc: '',
    fundC: '',
    rcno: '',
    rcnoKindTc: '',
    soptTc: '',
  }

  const defaultDsMsData = { 
    msData: '',
    pbprNo: '',
    msUseYn: '',
  }

  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
  import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
  import MSPTS206P from '~/src/ui/ts/MSPTS206P'       // 계좌번호 조회
  import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPTS95090D",
    screenId: "MSPTS95090D",
    components: {
      'ts-alert-popup': TSAlertPopup,
      'msp-ts-206p': MSPTS206P,
    },
    props: {
      dSearchObj: {type:Object, default:null},
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      // this.dSearchObj = Object.assign({}, this.propsObj) 
    },
    mounted() {
      this.fn_afterFormOnload()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {

        pbprUseYn: '',
        passYn: '',
        popCls: '',
        list_Cbo_accSeq:[], 
        Cbo_accSeq: '', // 계좌번호 select박스 value
        isMobile: window.vue.getStore('deviceState').getters.getIsMobile,

        Edt_acno: '', // 계좌번호
        edt_cnm: '',  // 이름
        edt_curC: '' ,
        cal_contDate: '',
        cal_mtrtDate: '',
        edt_brigAccYn: '',
        edt_trstTypC: '',
        edt_gdC: '',
        edt_trstAmt: '',
        edt_gdTypDtlC: '',
        edt_mngBrcd: '',
        edt_csId: '',
        edt_cnm: '',
        edt_fundC: '',
        edt_rcno: '',
        edt_rcnoKindTc: '',
        edt_soptTc: '',
        edt_gdNm: '',

        // 추가
        Edt_accStatC_inData: '',
        edt_accSeq: '',
        
        /***************************************************************************************
        * 메인 페이지에 따른 사용 변수
        *****************************************************************************************/
        cal_from: '',     //  거래내역 조회       dSearchObj.m_id == MSPTS110M
        cal_to: '',       //  거래내역 조회       dSearchObj.m_id == MSPTS110M
        cal_baseDate: '', //  신탁운용자산현황    dSearchObj.m_id == MSPTS102M

        /*********** 
         * 공통  
        ************/
        enabledList: { Cbo_accSeq:'' },
        visibleList: { Cbo_accSeq:'' },
        eaiCommObj: TSCommUtil.gfn_eaiCommObj(), // Contents Model 
        
        // 팝업 데이터 객체
        pPopup206Obj: {},   // 계좌번호 조회
        pAlertPopupObj: {}, // 공통 객체

        ds_list: [], //Object.assign({}, defaultDsList),
        ds_search: Object.assign({}, defaultDsSearch),
        DatasetTemp: Object.assign({}, defaultDatasetTemp),
        ds_msData: Object.assign({}, defaultDsMsData),
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      itemSlt1() {
        let rtn = [];
        rtn.push({value: '1', text: '리치플랜외화채권(U)'});
        rtn.push({value: '2', text: '리치플랜외화채권2'});
        return rtn;
      },  
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
     
      /***************************************************************************************
       * 조회
       * 기존에는 메인에 있어야할 버튼이 TOBE 에서 이쪽 화면으로 넘어옴.... 부모의 해당 함수를 호출한다
      *****************************************************************************************/
      fn_searchList () {
        this.$emit('ts-fn-search-list')
      },

      /***************************************************************************************
       * 초기화
       * 기존에는 메인에 있어야할 버튼이 TOBE 에서 이쪽 화면으로 넘어옴.... 부모의 해당 함수를 호출한다
      *****************************************************************************************/
      fn_init () {
        this.list_Cbo_accSeq = []
        this.Edt_acno = ''
        this.edt_cnm = ''
        this.$emit('ts-fn-init')
      },

       // 부모창에서 호출
      sendObj (context , pObj) {
        // this.popupObj = pObj
        // this.searchObj = Object.assign({}, context.$data.searchObj) 
        // Object.assign(this.searchObj, context.$data.m_searchObj) 
        this.dSearchObj.time = pObj.time
      },

      fn_test () {
        // test
        // this.dSearchObj.cal_to = Math.floor(Math.random() * 1000)
        
      },
      
      /***************************************************************************************
          실질적인 화면 초기화 
      *****************************************************************************************/
      fn_afterFormOnload ()
      { 
        this.pbprUseYn = "N";
        this.passYn = "N";
      },

      /************************************************************************************************
       * 데이터 초기화 
       ************************************************************************************************/
      _fn_dataInit ()
      { 
        this.Edt_acno = ''
        this.edt_cnm =''
        this.ds_list = []
        Object.assign(this.ds_search, defaultDsSearch)
        Object.assign(this.DatasetTemp, defaultDatasetTemp)
        Object.assign(this.ds_msData, defaultDsMsData)
      },

      /************************************************************************************************
       * 화면 조회 값 설정 
       ************************************************************************************************/
      _setValue ( strAcno, strAccSeq, strAcctStatC, strPopCls, strPassYn )
      {
      
        this.popCls = strPopCls;
        
        // 팝업구분에 따른 컨트롤 위치 변경 
        if ( strPopCls == "1" || strPopCls == "3" || strPopCls == "4") 
        {
          this.enabledList.Cbo_accSeq = false
          this.visibleList.Cbo_accSeq = false
        }
        
        if ( strPopCls == "0" ||  strPopCls == "2" ) 
        {
          this.enabledList.Cbo_accSeq = true
          this.visibleList.Cbo_accSeq = true
        }
        
        if ( !TSCommUtil.gfn_isNull(strPassYn)) {
          this.passYn = strPassYn; // 비밀번호 사용여부 
        }
        
        // 계좌 상태 코드 지정 
        let varAcctStatC = new String(TSCommUtil.gfn_nvl ( strAcctStatC , "" ) );	
        this.Edt_accStatC_inData = varAcctStatC  // 값이 없는 경우 계좌 전체 조회 
        this.edt_accSeq = strAccSeq // 계좌일련번호 
        this.Edt_acno = strAcno
        var varAcno = new String(strAcno);

        // 계좌번호를 입력받은 경우 바로 조회한다. 
        if ( TSCommUtil.gfn_length(this.Edt_acno) == 10 ) { 
          if(strPopCls == "4"){
            this.fn_transactionB(this.Edt_acno)
          }else{
            this.fn_transaction(this.Edt_acno)
          }
        }
        
      },

      /************************************************************************************************
       * 화면조회구분 세팅 
       * 호출하는 곳 없음
       ************************************************************************************************/
      fn_popCls ( strPopCls )
      {
        // this.popCls = strPopCls;
        
        // // 팝업구분에 따른 컨트롤 위치 변경 
        // if ( strPopCls == "1" || strPopCls == "3" || strPopCls == "4" ) 
        // {
        //   this.Cbo_accSeq.set_visible(false);
        //   this.Cbo_accSeq.set_enable(false);
        //   this.btn_popupTPUP95350.set_left(91);
        //   this.edt_cnm.set_left(115); 
        //   this.edt_cnm.set_width(200); 
        //   this.Div00.set_left(315);
        // }
        
        // if ( strPopCls == "0" ||  strPopCls == "2" ) 
        // {
        //   this.Cbo_accSeq.set_visible(true);
        //   this.Cbo_accSeq.set_enable(true);
        //   this.btn_popupTPUP95350.set_left(283); 
        //   this.edt_cnm.set_left(307); 
        //   this.edt_cnm.set_width(135); 
        //   this.Div00.set_left(442);
        // }
        
        // // 계좌번호를 입력받은 경우 바로 조회한다. 
        // if ( this.gfn_length( this.Edt_acno.value) == 10 ) { 
        //   if(strPopCls == "4"){
          
        //   this.fn_transactionB(this.Edt_acno.value);

        //   }else{
        //   this.fn_transaction(this.Edt_acno.value);
        //   }
        // }
      },

      /************************************************************************************************
       * 계좌번호 값 리턴 
       ************************************************************************************************/
      _getValue ()
      {		
         // 데이터가 있는경우 
        if ( this.ds_list.length > 0 ) {
          var oParamData = this.Edt_acno + this.Cbo_accSeq
          return oParamData;
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
       * 종합계좌번호 값 리턴 
       ************************************************************************************************/
      _getTacnoValue ()
      {	
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          
          var oParamData = this.Edt_acno;
          
          return oParamData.toString().substr(0,7);	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
       * ISA 계좌여부 값 리턴 
       ************************************************************************************************/
      _getIsaAcnoYn ()
      {	
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          // 특정금전신탁이고, 상세유형이 E@ISA 인것 
          if ( this.edt_trstTypC == "001" && this.edt_gdTypDtlC == "E" ) {
            var oParamData = "Y";	
          }
          else {
            var oParamData = "N";	
          }	
          return oParamData;
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
       * 통장일련번호 값 리턴 
       ************************************************************************************************/
      _getBnkbSeqValue ()
      {	
       // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.Edt_acno
          return oParamData.toString().substr(7,3);	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
       * 계좌일련번호 값 리턴 
       ************************************************************************************************/
      _getAccSeqValue ()
      {	
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.Cbo_accSeq
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
       * 상품명 값 리턴 
       ************************************************************************************************/
      _getGdNmValue ()
      {	
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.edt_gdNm
          return oParamData.toString().substr(4,100);	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
       * 고객명 값 리턴 
       ************************************************************************************************/
      _getCnmValue ()
      {	
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.edt_cnm;
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
       * 브릿지계좌여부 값 리턴 
       ************************************************************************************************/
      _getBrigAccYnValue ()
      {	
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.edt_brigAccYn;
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return '' 
        }	
      },

      /************************************************************************************************
       * 브릿지계좌번호 리턴 
       ************************************************************************************************/
      _getBridgeAcno () 
      {	
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          for ( var i = 0 ; i < this.ds_list.length ; i ++ ) {
            if ( this.ds_list[i].brigAccYn == "Y" ) {
              var oParamData = this.ds_list[i].tacno 
              oParamData += this.ds_list[i].bnkbSeq 
              oParamData += this.ds_list[i].accSeq 
              return oParamData;	
            }
          }		
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
       * 통화코드 값 리턴 
       ************************************************************************************************/
      _getCurCValue ()
      {	
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.edt_curC
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
      * 계약일자 값 리턴 
      ************************************************************************************************/
      _getContDateValue ()
      {	
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.cal_contDate
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
      * 만기일자 값 리턴 
      ************************************************************************************************/
      _getMtrtDateValue ()
      {	
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.cal_mtrtDate
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
      * 상품유형 값 리턴 
      ************************************************************************************************/
      _getTrstTypCValue ()
      {	
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.edt_trstTypC
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
      * 상품구분 값 리턴 
      ************************************************************************************************/
      _getGdCValue ()
      {	
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.edt_gdC
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
      * 신탁금액 값 리턴 
      ************************************************************************************************/
      _getTrstAmtValue ()
      {	
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.edt_trstAmt
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
      * 관리지점코드 값 리턴 
      ************************************************************************************************/
      _getMngBrcdValue ()
      {		
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.edt_mngBrcd
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return '' 
        }	
      },

      /************************************************************************************************
      * 고객ID 값 리턴 
      ************************************************************************************************/
      _getCsIdValue ()
      {	
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.edt_csId
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
      * 계좌여부 리턴
      ************************************************************************************************/
      _getBnkbAccYnValue ()
      {	
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.edt_bnkbAccYn
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
      * 고객ID 값 리턴 
      ************************************************************************************************/
      _getFundCValue ()
      {	
        // 데이터가 있는경우 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.edt_fundC
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
      * 실명번호 값 리턴 
      ************************************************************************************************/
      _getRcno ()
      {	
        // 데이터가 있는경우 
        if(!this.gfn_isNull(this.Edt_acno)  && !this.gfn_isNull(this.edt_rcno)){	
          var oParamData = this.edt_rcno
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },


      /************************************************************************************************
      * 실명구분 값 리턴 
      ************************************************************************************************/
      _getRcnoKindTc ()
      {	
        // 데이터가 있는경우 
          if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.edt_rcnoKindTc
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
      * 자타익구분 값 리턴 
      ************************************************************************************************/
      _getSoptTc ()
      {	
        // 데이터가 있는경우 
          if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          var oParamData = this.edt_soptTc
          return oParamData;	
        // 데이터가 없는 경우
        }else{
          return ''
        }	
      },

      /************************************************************************************************
      * 계좌 정보 조회 TR 전송 
      ************************************************************************************************/
      fn_transaction ()
      {
        // 계좌번호 조회 
        this.ds_list = []
        this.ds_search = Object.assign({}, defaultDsSearch)
        this.ds_search.tacno =  this.Edt_acno.toString().substr(0,7) 
        this.ds_search.bnkbSeq =  this.Edt_acno.toString().substr(7,3) 
        this.ds_search.accStatC =  this.Edt_accStatC_inData 
        this.ds_search.brigAccYn =  this.edt_brigAccYn01 
        
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600245_S'
        this.eaiCommObj.params = Object.assign({}, this.ds_search)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)

        // var sSvcID        = "selectTPUP95090List";
        // var sURL          = "svc::selectTPUP95090List.do";
        // var sInDatasets   = "tpup95090=ds_search";
        // var sOutDatasets  = "ds_list=tpup95090List";
        // var sArgument     = "";
        // var sCallbackFunc = "fn_callBack";
        
        // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);
      },

      /************************************************************************************************
      * 통장 정보 조회 TR 전송 
      ************************************************************************************************/
      fn_transactionB ()
      {
       // 통장번호 조회 
        Object.assign(this.ds_list, defaultDsList) // 데이터 셋 초기화
        Object.assign(this.ds_search, defaultDsSearch) // 데이터 셋 초기화
        
        this.ds_search.tacno =  this.Edt_acno.value.toString().substr(0,7)
        this.ds_search.bnkbSeq =  this.Edt_acno.value.toString().substr(7,3)
        
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600246_S'
        this.eaiCommObj.params = Object.assign({}, this.ds_search)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)

        
        // var sSvcID        = "selectTPUP95090ListB";
        // var sURL          = "svc::selectTPUP95090ListB.do";
        // var sInDatasets   = "tpup95090=ds_search";
        // var sOutDatasets  = "ds_list=tpup95090List";
        // var sArgument     = "";
        // var sCallbackFunc = "fn_callBack";
        
        // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);
      },

      /************************************************************************************************
      * 계좌조회 정상응답 후 처리 
      ************************************************************************************************/
      fn_callBack ( res )
      {
        // 응답코드가 정상이 아닌경우 
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          // this.fn_AlertPopup(0,{content : resData.errorMsg}) 
          this.$emit('ts-alert-popup', 0, {content : resData.errorMsg})
          return false
        }

        if ( TSCommUtil.gfn_length(resData.tpup95090) > 0 ) {
          // Object.assign ( this.ds_list, resData.tpup95090) 
          this.ds_list = resData.tpup95090
          let rtn = []
          this.ds_list.forEach(function(item,idx){
            rtn.push({value: item.accSeq , text: item.gdNm })
          })
          this.list_Cbo_accSeq = rtn
        }
        else {
          // this.fn_AlertPopup(0,{content : '결과가 존재하지 않습니다.'}) 
          this.$emit('ts-alert-popup', 0, {content : '결과가 존재하지 않습니다.'})
          return false
        }
      
        // 초기값 설정 
        this.edt_curC = this.ds_list[0].curC
        this.cal_contDate = this.ds_list[0].contDate
        this.cal_mtrtDate = this.ds_list[0].mtrtDate
        this.edt_brigAccYn = this.ds_list[0].brigAccYn
        this.edt_trstTypC = this.ds_list[0].trstTypC
        this.edt_gdC = this.ds_list[0].gdC
        this.edt_trstAmt = this.ds_list[0].trstAmt
        this.edt_gdTypDtlC = this.ds_list[0].gdTypDtlC
        this.edt_mngBrcd = this.ds_list[0].mngBrcd
        this.edt_csId = this.ds_list[0].csId
        this.edt_cnm = this.ds_list[0].cnm
        this.edt_fundC = this.ds_list[0].fundC
        this.edt_rcno = this.ds_list[0].rcno
        this.edt_rcnoKindTc = this.ds_list[0].rcnoKindTc
        this.edt_soptTc = this.ds_list[0].soptTc
        this.edt_gdNm = this.ds_list[0].gdNm
        
        if ( this.popCls == "2" ||  this.popCls == "3" ) {
          if ( !TSCommUtil.gfn_isNull(this.ds_list[0].admkNm)) {
            this.edt_cnm = this.ds_list[0].cnm + "[" + this.ds_list[0].admkNm + "]" 
          }
          this.edt_bnkbAccYn = "Y"  
        }else if(this.popCls == "4"){
          if(svcID == "selectTPUP95090ListB"){
            if ( !TSCommUtil.gfn_isNull(this.ds_list[0].bnkb)) { //등록된 계좌가 있는지 
              this.edt_bnkbAccYn = "Y" //등록된 계좌있음
              this.fn_transaction(this.Edt_acno);
            }else{
              this.edt_bnkbAccYn = "N" //등록된 계좌없음
            }
          }else{
            this.edt_bnkbAccYn = "Y" //등록된 계좌있음
          }
          if ( !TSCommUtil.gfn_isNull(this.ds_list[0].admkNm)) {
            this.edt_cnm = this.ds_list[0].cnm + "[" + this.ds_list[0].admkNm + "]" 
          }
        }
        
        if ( !TSCommUtil.gfn_isNull(this.edt_accSeq)) { // 계좌일련번호가 존재하면 바로 세팅 
          this.Cbo_accSeq = this.edt_accSeq
          //  this.Cbo_accSeq_onitemchanged(); watch에서 처리
          return ;
        }
        else {
          this.Cbo_accSeq = this.ds_list[0].accSeq // this.Cbo_accSeq.set_index(0);
        }
          
        // 계좌번호가 있는 경우에만 호출화면으로 메소드를 전달한다. 
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          try{
            if ( this.passYn == "Y" ) {
              console.lg('acctCallBackFuncTacno 해당 함수')
              // this.lookupFunc("acctCallBackFuncTacno").call(); // 존재하지 않음....
            }
            else {
              this.$emit('ts-acctCallBackFunc', 0, result)
              // this.lookupFunc("acctCallBackFunc").call();
            }
          } catch(e){
          }	
        }
        
      },

      /******************************************************************************
       * Function명 : fn_SearchValid
       * 설명       : 계좌번호 입력 시 필수값 검증 후 조회, 
       ******************************************************************************/
      Edt_acno_onkeyup(event, el) {
        console.log('Edt_acno_onkeyup.....')
        // var acnoValue = this.Edt_acno.value;
        let acnoValue = this.Edt_acno
        const _el = this.$refs[el].$el
      
        // 계좌번호 10자리 입력시 자동 조회 
        if ( TSCommUtil.gfn_length(acnoValue) == 10 ) {

          if( !TSCommUtil.gfn_isNum(event.key) && event.key !='Enter' && event.key != 'v' && !this.isMobile ) {
            return 
          }
          this.edt_accSeq = ''
          Object.assign(this.ds_msData, defaultDsMsData)
          if(this.popCls == "4"){
            this.fn_transactionB(acnoValue);
          }else{
            this.fn_transaction(acnoValue);
          }
          this.fn_validErrRemoveInput(event, 'Edt_acno') // error class 삭제 체크
        }
        // else {
        //   // 계좌번호 입력 후 엔터 시 계좌 조회 tr 호출 
        //   if ( event.key == 'Enter' ) {
        //     if ( TSCommUtil.gfn_indexOf(acnoValue," ",0) < 0 && TSCommUtil.gfn_length(acnoValue) != 10 ) {
        //       //this.alert("계좌번호를 다시 입력하시오.");
        //       TSCommUtil.gfn_validate(this, '계좌번호를 다시 입력하시오.')
        //       _el.focus() // 포커스
        //       if ( ! _el.classList.contains('error') ) {  // class에 erorr가 없을시만 추가
        //         _el.classList.add('error')
        //       }
        //     }
        //     else {
        //       this.edt_accSeq = ''
        //       Object.assign(this.ds_msData, defaultDsMsData)
        //       this.fn_transaction(acnoValue);
        //       this.fn_validErrRemoveInput(event, 'Edt_acno') // error class 삭제 체크
        //     }
        //   }
        // }
      },

      /************************************************************************************************
      * 콤보박스 변경 이벤트 
      ************************************************************************************************/
      Cbo_accSeq_onitemchanged ()
      {
        for ( let idx in this.ds_list ) {
          if ( this.Cbo_accSeq == this.ds_list[idx].accSeq ) {
            console.log(this.ds_list[idx] )

            // 콤보값이 변경될때마다 데이터 변경 
            this.edt_curC = this.ds_list[idx].curC
            this.cal_contDate = this.ds_list[idx].contDate
            this.cal_mtrtDate = this.ds_list[idx].mtrtDate
            this.edt_brigAccYn = this.ds_list[idx].brigAccYn
            this.edt_trstTypC = this.ds_list[idx].trstTypC
            this.edt_gdC = this.ds_list[idx].gdC
            this.edt_trstAmt = this.ds_list[idx].trstAmt
            this.edt_gdTypDtlC = this.ds_list[idx].gdTypDtlC
            this.edt_mngBrcd = this.ds_list[idx].mngBrcd
            this.edt_csId = this.ds_list[idx].csId
            this.edt_fundC = this.ds_list[idx].fundC
            this.edt_rcno = this.ds_list[idx].rcno
            this.edt_rcnoKindTc = this.ds_list[idx].rcnoKindTc
            this.edt_soptTc = this.ds_list[idx].soptTc
            this.edt_gdNm = this.ds_list[idx].gdNm
            break
          }
        }

        // 계좌번호가 있는 경우에만 호출화면으로 메소드를 전달한다. 	
        if ( this.ds_list.length > 0 ) {
          try{
            this.fn_searchList() // this.lookupFunc("acctCallBackFunc").call();
          } catch(e){
          }	
        }
      },

      /************************************************************************************************
      * 데이터 변경시 계좌일련번호 콤보와 고객명 초기화 
      * TODO : 적용해야함
      ************************************************************************************************/
      Edt_acno_ontextchange ()
      {
        if ( TSCommUtil.gfn_length(this.ds_list) > 0 ) {
          this.ds_list.clearData();	
        }
        
        if ( TSCommUtil.gfn_length(this.ds_msData) > 0 ) {
          this.ds_msData.clearData();	
        }
      },

      /************************************************************************************************
      * 통장 리드 후 
      ************************************************************************************************/
      pbprCallBack ()
      {
      },   

      /************************************************************************************************
      * 통장 ms 데이터 전송
      ************************************************************************************************/
      _getMsData ()
      {
        return '' // 2021-08-17 add
      },  

      /************************************************************************************************
      * 통장 사용여부 
      ************************************************************************************************/
      _getMsUseYn ()
      {
        return '' // 2021-08-17 add
      }, 
      
      /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type) : 0: 계좌조회 
       ******************************************************************************/
      fn_OpenPopup(type, flag) {
        switch (type) {
          case 0:
            this.$refs.popup206.fn_Open()
            break          
        }
      },

      /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : 계좌조회(206p) 컴포넌트에서 callback
       ******************************************************************************/
      fn_Popup_CallBack ( pData ) {
        console.log(pData)
        
        if(!TSCommUtil.gfn_isNull(pData.tacno)){
          Object.assign(this.DatasetTemp, defaultDatasetTemp),
          // this.DatasetTemp.loadXML(strVal);
          this.Edt_acno = pData.tacno + pData.bnkbSeq
          this.edt_accSeq = pData.accSeq
          this.fn_transaction(); // 조회 처리 
        }	
        
      },

      /******************************************************************************
       * Function명 : fn_validErrRemoveInput
       * 설명       : input, mo-text-field 컴포넌트의 css error class 삭제
       ******************************************************************************/
      fn_validErrRemoveInput (event, el) {
        const value = event.currentTarget.value
        const _el = this.$refs[el].$el
        if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(value) ) )
          _el.classList.remove('error')
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      Cbo_accSeq : {
        handler: function(val, oldVal) { 
          if ( val != oldVal ) 
            this.Cbo_accSeq_onitemchanged()
        },
      },
      
      // error class 삭제
      'dSearchObj.cal_from' : {
        handler: function(val, oldVal) { 
          this.$refs.cal_from.$el.classList.remove('error')
        },
      },

      // error class 삭제
      'dSearchObj.cal_to' : {
        handler: function(val, oldVal) { 
          this.$refs.cal_to.$el.classList.remove('error')
        },
      },

      'dSearchObj.cal_baseDate': {
        handler: function(val, oldVal) {
          this.$refs.cal_baseDate.$el.classList.remove('error')
        },
      },

    }
    
  }
</script>

<style lang="scss">
</style>

<style scoped>
</style>
